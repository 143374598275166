import React, {useState} from "react"
import { Link } from "gatsby"

//import GooglePlaces from "../components/base/google-places";
import MapboxPlaces from "../components/base/mapbox-places";
//import AutoComplete from "react-google-autocomplete"
//import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

import Logo from '../images/uhslogo.svg'
import ChildrenPhoto from '../images/home/children-photo.jpg'
import ResidentialPhoto from '../images/home/residential-photo.jpg'
import OutpatientPhoto from '../images/home/outpatient-photo.jpg'
//import HomePlaces from '../components/base/home-places'
import Layout from "../components/base/layout"
import SEO from "../components/base/seo"

function componentDidMount() {
  if (typeof window !== undefined) {
    let query = window.location.search;
    window.location.replace(`/search/${query}`);
  }
}

const IndexPage = (props) => {

  //const [value, setValue] = useState(null);

  return (
  <Layout location={props.location}>
    <SEO title="Home" />
    <div className="sl_content sl_content--home">

      {/* Intro Search Module */}
      <div className="sl_module sl_module--search">
        <div className="sl_inner">
          <Link className="sl_logo" to="/search">
            <img alt="UHS Header Logo" src={Logo}></img>
          </Link>
          <h1>Hope. Help. <span>Healing.</span></h1>
          <p>Find a UHS behavioral health location near you.</p>
          {/*<HomePlaces
          className="sl_module--search__box"
        />*/}
          <span className="algolia-places">
            {/*<GooglePlaces />*/}
            <MapboxPlaces />
          {/*<AutoComplete
              apiKey="AIzaSyDgE2kUF5WhO3jUh7fTiBzm9G7Q8F-tt0E"
              options={{
                types: ["(regions)"],
                componentRestrictions: { country: "us" },
              }}
              onPlaceSelected={(place) =>
                  //console.log('Lat: ' + place.geometry.location.lat() + ' Lng:' + place.geometry.location.lng())
                  window.location.replace(`/search/?aroundLatLng%5Blat%5D=${place.geometry.location.lat()}&aroundLatLng%5Blng%5D=${place.geometry.location.lng()}&configure%5BhitsPerPage%5D=40`)
                }
              class="ap-input"
              placeholder="Search by city or zip"
          />*/}
          </span>
        </div>{/*end sl_inner*/}
      </div>{/*end sl_module--search*/}

      <div className="sl_module sl_module--filterdeck sl_module--withbottom">
      <div className="sl_inner">
        <div className="sl_row small-up-1 medium-up-1 large-up-3">

          <div className="sl_cell">
            {/*<a className="sl_card sl_card--filterdeck" href="/search/?refinementList%5Bservices.age.ageGroup%5D%5B0%5D=child&refinementList%5Bservices.age.ageGroup%5D%5B1%5D=adolescent&page=1&configure%5BhitsPerPage%5D=40">*/}
            <a className="sl_card sl_card--filterdeck" href="/search/?refinementList%5Bservices.age.ageGroup%5D%5B0%5D=-adult&page=1&configure%5BhitsPerPage%5D=40">
              <div className="sl_card__image" style={{backgroundImage: `url(${ChildrenPhoto})`}}></div>
              <div className="sl_card__content">
                <h3>Children and Adolescents</h3>
                <p>Looking for a specific age demographic?</p>
              </div>
            </a>
          </div>{/*end sl_cell */}

          <div className="sl_cell">
            <a className="sl_card sl_card--filterdeck" href="/search/?refinementList%5Bservices.levelCare.name%5D%5B0%5D=Residential&page=1&configure%5BhitsPerPage%5D=40&configure%5BaroundRadius%5D=all">
              <div className="sl_card__image" style={{backgroundImage: `url(${ResidentialPhoto})`}}></div>
              <div className="sl_card__content">
                <h3>Residential Treatment</h3>
                <p>Find facilities that offer inpatient stay</p>
              </div>
            </a>
          </div>{/*end sl_cell */}

          <div className="sl_cell">
            <a className="sl_card sl_card--filterdeck" href="/search/?refinementList%5Bservices.levelCare.name%5D%5B0%5D=Outpatient&refinementList%5Bservices.levelCare.name%5D%5B1%5D=Intensive%20Outpatient%20Program%20%28IOP%29&refinementList%5Bservices.levelCare.name%5D%5B2%5D=Partial%20Hospitalization%20Program%20-%20Plus%20%28PHP%2B%29&refinementList%5Bservices.levelCare.name%5D%5B3%5D=Partial%20Hospitalization%20Program%20%28PHP%29&refinementList%5Bservices.levelCare.name%5D%5B4%5D=Telehealth&page=1&configure%5BhitsPerPage%5D=40&configure%5BaroundRadius%5D=all">
              <div className="sl_card__image" style={{backgroundImage: `url(${OutpatientPhoto})`}}></div>
              <div className="sl_card__content">
                <h3>Outpatient Treatment</h3>
                <p>Need to maintain a flexible schedule?</p>
              </div>
            </a>
          </div>{/*end sl_cell */}

          </div>{/*end sl_row */}
        </div>{/*end sl_inner */}
      </div>{/*end sl_module--filterdeck */}

      <div className="sl_module sl_module--callout">
        <div className="sl_inner">
          <div className="sl_card sl_card--callout">
            <h2>Looking for all of our UHS locations?</h2>
            <p>Visit our website to see a map of all UHS locations including Acute Care hospitals, Freestanding Emergency Departments, Behavioral Health Facilities, Ambulatory Surgery Centers and Physician Networks.</p>
            <a className="sl_button sl_button--border sl_button--external" href="https://www.uhsinc.com/our-communities/" target="_blank" rel="noreferrer">Visit Website</a>
          </div>{/*end sl_card */}
        </div>{/*end sl_inner */}
      </div>{/*end sl_module--callout */}
    </div>{/*end sl_content */}

    <div className="sl_content__footer sl_footer">
        <div className="sl_inner">
          <p>UHS is a registered trademark of UHS of Delaware, Inc., a subsidiary of Universal Health Services. Universal Health Services, Inc. is a holding company that operates through its subsidiaries. All healthcare and management operations are conducted by subsidiaries of Universal Health Services, Inc. To the extent there is any reference to “UHS” or “UHS facilities” on this website, including any statements, articles or other publications contained herein which related to healthcare or management operations, they are referring to Universal Health Services’ subsidiaries. Further, the terms "we," "us," "our" or "the company" in such context similarly refer to the operations of the subsidiaries of Universal Health Services, Inc. Any reference to employment at UHS or employees of UHS refers to employment with one of the subsidiaries of Universal Health Services, Inc.</p>
          <p>Your individual health status and any required medical treatments can only be properly addressed by a professional healthcare provider. Physicians are on the medical staff of these facilities, but, with limited exceptions, are independent practitioners who are not employees or agents of these facilities. The facilities shall not be liable for actions or treatments provided by physicians. Model representations of real patients are shown. Actual patients cannot be divulged due to HIPAA regulations. For language assistance, disability accommodations and the <a target={"_blank"} href={"https://uhs.com/wp-content/uploads/2023/12/UHS_Inc_Notice_of_Nondiscrimination.pdf"}>non-discrimination notice</a>, visit their websites.</p>
        </div>{/*end sl_inner */}
      </div>{/*end sl_footer */}
  </Layout>
  )
}

export default IndexPage
